import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import EvalForm from '../components/content/form/EvalForm'
import withTranslations from '../components/hoc/withTranslations'
import Banner from '../components/layout/Banner'
import EvalBanner from '../images/eval_banner.png'
import MainLayout from '../layouts/MainLayout'
import PageContext from '../types/PageContext'
import Translation from '../types/Translation'

const ProductEvalPageTemplate: React.FunctionComponent<ProductEvalPageTemplateProps> = (props: ProductEvalPageTemplateProps) => {
    const { pageContext, t } = props

    return (
        <MainLayout
            pageContext={pageContext}
            banner={
                <Banner
                    header={t('template', 'eval.banner.header')}
                    headerText={t('template', 'eval.banner.headertext')}
                    imageSrc={EvalBanner}
                />
            }
        >
            <Container>
                <Row>
                    <Col>
                        <p>{t('template', 'eval.intro.text')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <EvalForm pageContext={pageContext} />
                    </Col>
                </Row>
            </Container>
        </MainLayout>
    )
}

export default withTranslations(ProductEvalPageTemplate)

interface ProductEvalPageTemplateProps extends Translation {
    pageContext: PageContext<any>
}
