import React from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap'

import Select from 'react-select'
import { saveEvalForm } from '../../../helper/SessionStorage'
import { isLoggedIn } from '../../../services/auth.js'
import { IFilterOption } from '../../../types/Common'
import Location from '../../../types/Location'
import PageContext from '../../../types/PageContext'
import { IVariant } from '../../../types/Product'
import Translation from '../../../types/Translation'
import axios from '../../../utils/axios'
import withLocation from '../../hoc/withLocation'
import withTranslations from '../../hoc/withTranslations'
import ContentButton from '../ContentButton'
import AnyLink from '../../AnyLink'
import MyPDFRegisterPlugin from '../plugin/MyPDFRegisterPlugin'
import * as styles from './EvalForm.module.scss'
import GenericForm from './GenericForm'

class EvalForm extends React.Component<EvalFormProps, EvalFormState> {
    constructor(props: EvalFormProps) {
        super(props)

        this.state = {
            form: {
                selectedProductVariant: { value: '', label: '' },
                selectedPlatform: { value: '', label: '' },
                platformSelection: [],
                acceptedTerms: false,
                errorLicense: false,
            },
            variants: [],
            variantOptions: [],
            errorMsg: '',
        }
    }

    componentDidMount = async () => {
        const { templateData } = this.props.pageContext
        const { product } = templateData
        const productCode = '_' + product.code

        const url = `/api/v1.0/Kits/Evalitems/${productCode}`

        try {
            const response = await axios.get(url)

            if (response && response.data) {
                const evalItems: IVariant[] = response.data
                this.initialize(evalItems)
            }
        } catch (error: any) {
            console.log(error)

            if (error.response) {
                console.log(`status code: ${error.response.status}`)
                console.log(error.response.data)
            }

            this.displayFormError(this.props.t('form', 'general.form.error'))
        }
    }

    handleSubmit = async () => null

    initialize = (variants: IVariant[]) => {
        const variantOptions = variants.map((variant) => ({
            value: variant.variant,
            label: variant.variant,
        }))

        const defaultVariant = variants[0]
        const selectedProductVariant = variantOptions[0]
        const platformSelection = this.getPlatformSelection(defaultVariant)

        const selectedPlatform = platformSelection[0]

        const newState = {
            form: {
                selectedProductVariant,
                selectedPlatform,
                platformSelection,
                acceptedTerms: false,
                errorLicense: false,
            },
            variants,
            variantOptions,
            errorMsg: '',
        }

        this.setState(newState)
    }

    handleProductVariantChange = (opt: any) => {
        let platformSelection: IFilterOption[] = []

        const platforms = this.getPlatformSelectionByVariantName(opt.value)
        let newSelectedPlatform: IFilterOption = { value: '', label: '' }

        if (platforms) {
            platformSelection = platforms
            newSelectedPlatform = platformSelection[0]
        }

        this.setState({
            form: {
                ...this.state.form,
                selectedProductVariant: opt,
                selectedPlatform: newSelectedPlatform,
                platformSelection,
            },
        })
    }

    handleOSChange = (opt: any) => {
        this.setState({
            form: {
                ...this.state.form,
                selectedPlatform: opt,
            },
        })
    }

    showLicenseError = () => {
        if (!this.state.errorMsg) {
            this.setState({
                form: {
                    ...this.state.form,
                    errorLicense: true,
                },
                errorMsg: this.props.t('template', 'error.license.terms'),
            })
        }
    }

    toggleAcceptTerms = () => {
        if (!this.state.form.acceptedTerms) {
            this.setState({
                form: {
                    ...this.state.form,
                    acceptedTerms: !this.state.form.acceptedTerms,
                    errorLicense: false,
                },
                errorMsg: this.state.form.errorLicense ? '' : this.state.errorMsg,
            })
        } else {
            this.setState({
                form: {
                    ...this.state.form,
                    acceptedTerms: !this.state.form.acceptedTerms,
                },
            })
        }
    }

    handleLoggedInDownload = () => {
        if (this.state.form.acceptedTerms) {
            this.saveFormToSession('login')

            const { templateData } = this.props.pageContext
            const { evalDownloadUrl } = templateData

            this.props.navigate(evalDownloadUrl)
        } else {
            this.showLicenseError()
        }
    }

    saveFormToSession = (type: string) => {
        const { templateData } = this.props.pageContext
        const { product } = templateData

        const platformCode = this.state.form.selectedPlatform.value
        const productCode = '_' + product.code
        const productName = product.name

        saveEvalForm(productCode, platformCode, productName, type)
    }

    displayFormError(errorMsg: string) {
        this.setState({
            errorMsg,
        })
    }

    getPlatformSelectionByVariantName(name: string) {
        const foundVariant = this.state.variants.find((variant) => variant.variant === name)

        if (foundVariant) {
            return this.getPlatformSelection(foundVariant)
        }

        return null
    }

    getPlatformSelection(variant: IVariant) {
        const platformSelection = variant.platforms.map((platform) => ({
            value: platform.code,
            label: platform.platform,
        }))

        return platformSelection
    }

    renderFormTitle() {
        const { product } = this.props.pageContext.templateData
        const title = this.props.t('form', 'eval.form.title')

        return title.replace('%1', product.name)
    }

    renderLicenseLabel() {
        const { t } = this.props

        return (
            <div>
                <span>{t('form', 'license.terms.label') + ' '}</span>
                <AnyLink to={'/public/downloads/licenses/' + t('slug', 'license.terms.filename')}>
                    {t('form', 'license.terms.label.link')}
                </AnyLink>
            </div>
        )
    }

    render() {
        const { t, pageContext } = this.props
        const { evalDownloadUrl } = pageContext.templateData
        const { selectedProductVariant, selectedPlatform, platformSelection, acceptedTerms } = this.state.form
        const { variantOptions, errorMsg } = this.state

        const formTitle = this.renderFormTitle()
        const renderLicenseLabel = this.renderLicenseLabel()

        const platformSelectionCount = platformSelection.filter((ps) => !!ps.label).length

        return (
            <GenericForm onSubmit={this.handleSubmit}>
                <Row className={styles.titleContainer}>
                    <Col sm={12}>
                        <h1>{formTitle}</h1>
                        <p>{t('form', 'eval.form.text.testproduct')}</p>
                    </Col>
                </Row>

                <Row className={styles.formGroup}>
                    <Col sm={4}>
                        <Form.Label>{t('form', 'eval.field.productvariant.label')}*</Form.Label>
                    </Col>
                    <Col sm={4}>
                        <Select
                            value={selectedProductVariant}
                            onChange={this.handleProductVariantChange}
                            options={variantOptions}
                            classNamePrefix={'pdft_select'}
                        />
                    </Col>
                </Row>

                {platformSelectionCount > 0 && (
                    <Row className={styles.formGroup}>
                        <Col sm={4}>
                            <Form.Label>{t('form', 'eval.field.os.label')}*</Form.Label>
                        </Col>
                        <Col sm={4}>
                            <Select
                                value={selectedPlatform}
                                onChange={this.handleOSChange}
                                options={platformSelection}
                                classNamePrefix={'pdft_select'}
                                isDisabled={platformSelectionCount <= 1}
                            />
                        </Col>
                    </Row>
                )}
                {isLoggedIn() ? (
                    <>
                        <Row>
                            <Col sm={4} />
                            <Col sm={4}>
                                <Form.Group
                                    controlId="formBasicCheckbox"
                                    className={this.state.form.errorLicense ? 'invalid' : ''}
                                >
                                    <Form.Check
                                        type="checkbox"
                                        label={renderLicenseLabel}
                                        name="acceptedTerms"
                                        inline={true}
                                        required={true}
                                        checked={acceptedTerms}
                                        onChange={this.toggleAcceptTerms}
                                        className="is-invalid"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} />
                            <Col sm={4}>
                                <ContentButton block={true} onClick={this.handleLoggedInDownload}>
                                    {t('form', 'eval.download.btn')}
                                </ContentButton>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Col>
                            <MyPDFRegisterPlugin
                                config={this.props.config}
                                pageContext={this.props.pageContext}
                                pluginData={this.props.pluginData}
                                evalDownloadUrl={evalDownloadUrl}
                                countries={this.props.pageContext.templateData.countries}
                                langOptions={this.props.pageContext.templateData.languageOptions}
                                acceptedTerms={this.state.form.acceptedTerms}
                                platform={this.state.form.selectedPlatform}
                                productVariant={this.state.form.selectedProductVariant.label}
                            >
                                <Col>
                                    <Form.Group
                                        controlId="formBasicCheckbox"
                                        className={this.state.form.errorLicense ? 'invalid' : ''}
                                    >
                                        <Form.Check
                                            type="checkbox"
                                            label={renderLicenseLabel}
                                            name="acceptedTerms"
                                            inline={true}
                                            required={true}
                                            checked={acceptedTerms}
                                            onChange={this.toggleAcceptTerms}
                                            className="is-invalid"
                                        />
                                    </Form.Group>
                                </Col>
                            </MyPDFRegisterPlugin>
                        </Col>
                    </Row>
                )}
                {errorMsg && <Alert variant={'danger'}>{errorMsg}</Alert>}
            </GenericForm>
        )
    }
}

export default withLocation(withTranslations(EvalForm))

interface EvalFormProps extends Translation, Location<any> {
    onSubmit?: any
    config: any
    pluginData: any
    pageContext: PageContext<any>
}

interface EvalFormState {
    form: {
        selectedProductVariant: IFilterOption
        selectedPlatform: IFilterOption
        platformSelection: IFilterOption[]
        acceptedTerms: boolean
        errorLicense: boolean
    }
    variants: IVariant[]
    variantOptions: IFilterOption[]
    errorMsg: string
}
